import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogHousingTrends20251.jpg";
const image2 = "/images/Blogs/BlogHousingTrends20252.jpg";
const image3 = "/images/Blogs/BlogHousingTrends20253.jpg";
const image4 = "/images/Blogs/BlogHousingTrends20254.jpeg";
const image5 = "/images/Blogs/BlogHousingTrends20255.png";
const image6 = "/images/Blogs/BlogHousingTrends20256.jpg";
const image7 = "/images/Blogs/BlogHousingTrends20257.jpg";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "growthFactors",
        text: "What's fueling this growth?",
    },
    {
        id: "marketOverview",
        text: "Indian Real Estate Market Overview",
    },
    {
        id: "keyTrends",
        text: "Key Trends Shaping Indian Real Estate",
    },
    {
        id: "multigenerationalLiving",
        text: "The Rise of Multigenerational Living",
    },
    {
        id: "sustainableBuildings",
        text: "Sustainable and Green Buildings on the Rise",
    },
    {
        id: "seniorLiving",
        text: "India's Senior Living Market",
    },
    {
        id: "evolvingTechnology",
        text: "Evolving Technology",
    },
    {
        id: "coLiving",
        text: "Co-Living Spaces",
    },
    {
        id: "coLivingDemand",
        text: "What is driving the demand for Co-Living Space?",
    },
    {
        id: "tier2Cities",
        text: "Up and Coming Tier II cities",
    },
    {
        id: "internationalInvestment",
        text: "International Investment Trends",
    },
    {
        id: "luxuryHousing",
        text: "Luxury & High-End Housing",
    },
    {
        id: "bangaloreMarket",
        text: "Market Growth and Price Trends in Bangalore",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQs ( Frequently Asked Questions)",
    },
];
const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};

const data = {
    slug: BLOGS.HOUSING_TRENDS_2025.slug,
    image: image1,
    imageAlt: BLOGS.HOUSING_TRENDS_2025.title,
    title: BLOGS.HOUSING_TRENDS_2025.title,
    time: BLOGS.HOUSING_TRENDS_2025.time,
    publishedOn: BLOGS.HOUSING_TRENDS_2025.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: (
        <>
            The Indian real estate market is riding a wave of transformation,
            making it one of the most dynamic sectors in the country. As the
            third-largest job provider after agriculture and non-financial
            services, real estate is setting up for exponential growth, with
            projections estimating its value at a whopping{" "}
            <a
                href="https://timesofindia.indiatimes.com/business/india-business/indias-real-estate-sector-to-grow-multifold-to-about-6-trillion-by-2047-report/articleshow/103108698.cms"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                $6 trillion by 2047
            </a>{" "}
            (approximately 498 lakh crore INR). In 2025, the industry is on
            track for significant developments in residential, commercial, and
            rental markets, all driven by urbanisation, tech integration, and
            evolving lifestyle trends. Join us as we unpack the trends and
            factors shaping Indian real estate in 2025.
        </>
    ),
    image2: image2,
    image2Alt: "Graph",

    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            It’s the surge in urbanisation, rising incomes, and the growing need
            for everything from homes and offices to logistics hubs and data
            centres. Experts are forecasting robust growth in residential,
            commercial, and rental segments, projecting the industry to hit an
            incredible USD 5.8 trillion ( approximately 481.4 trillion INR) by
            2047. With new opportunities on the horizon, the future of real
            estate in India looks brighter than ever, promising dynamic changes
            and steady progress across the sector!
        </>
    ),

    title4: <TitleEl content={tableOfContents[2]} />,
    description41: (
        <>
            <span className="font-benton-bold">Economic Growth:</span> The
            overall real estate market is anticipated to expand significantly by
            2025, with luxury housing sales showing remarkable growth in 2024,
            indicating sustained demand.
            <br></br>
            <span className="font-benton-bold">Market Size:</span> The total
            value of the Indian real estate market in 2025 is estimated to be
            around $300 billion, which is approximately ₹24 lakh crore (Indian
            Rupees).
            <br></br>
            <span className="font-benton-bold">
                Residential vs. Commercial:
            </span>
        </>
    ),
    list42: [
        {
            label: "Residential",
            description:
                "This segment accounts for 80% of the total market size. It includes houses, apartments, and other residential properties.",
        },
        {
            label: "Commercial",
            description:
                "This segment makes up 20% of the market and includes offices, retail spaces, and industrial properties.",
        },
    ],
    image4: image3,
    image4Alt: "Residential Vs Commercial",
    list43: [
        {
            label: (
                <>
                    <a
                        href="https://mospi.gov.in/sites/default/files/Statistical_year_book_india_chapters/HOUSING.pdf"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Housing Shortfall
                    </a>
                </>
            ),
            description:
                "There is a current shortfall of 1.8 crore (18 million) housing units and this shortage is expected to rise to 7 crore (70 million) units by 2030.",
        },
    ],

    title5: <TitleEl content={tableOfContents[3]} />,
    title6: <TitleEl content={tableOfContents[4]} />,

    description61: (
        <>
            Although Indians have lived in traditional joint families for
            centuries, the newer generations are discovering fresh interest in
            this way of life. This has resulted in the new trend of
            multigenerational living gaining momentum in India, and it's
            expected to grow even stronger in 2025. With rising costs, many
            families are choosing to live together, benefiting from shared
            utility bills, the ability to care for ageing parents, and support
            in child-rearing. This shift is driving the demand for homes that
            accommodate the diverse needs of all family members, from toddlers
            to grandparents. Jogging tracks and a variety of other sports
            facilities, along with clubhouses and recreational centres set
            within green spaces, have become essential. These features are
            necessary to cater to the diverse needs of all community members.
            <br></br>
            <img
                src={image4}
                alt={tableOfContents[4].text}
                className="image margin"
            />
            <br></br>
            At Modern Spaaces, we’re excited to meet this demand with
            thoughtfully designed homes that offer both privacy and space for
            family connection. Our homes feature spacious bedrooms for personal
            comfort, as well as communal areas with age-appropriate amenities
            that encourage interaction and togetherness.
            <br></br>
            Abhilash Cannoth, our VP of Sales, shares, “At Serene Heights, we
            aim to create inclusive spaces that seamlessly blend comfort and
            connection. These thoughtfully designed multigenerational homes
            cater to the needs of every family member, ensuring a harmonious
            living experience.”
        </>
    ),

    title7: <TitleEl content={tableOfContents[5]} />,
    description71: (
        <>
            Sustainability in the last few years has gained the right kind of
            attention it deserves and as we head into 2025, the focus on green,
            energy-efficient buildings is only growing. Homebuyers are
            increasingly drawn to eco-friendly designs and energy-saving
            features, and developers are stepping up to meet the demand with
            sustainable materials and construction methods.
            <br></br>
            <img
                src={image5}
                alt={tableOfContents[5].text}
                className="image margin"
            />
            <br></br>
            At Modern Spaaces, we’re fully embracing the green revolution. Our
            projects showcase sustainable features like solar panels, rainwater
            harvesting, open spaces, and native trees—connecting residents with
            nature and boosting energy efficiency. We’re committed to building
            homes that are{" "}
            <span className="font-benton-bold">
                sustainable, energy-efficient, and environmentally responsible
            </span>{" "}
            because we believe a greener home leads to a brighter future for
            everyone.
        </>
    ),

    title8: <TitleEl content={tableOfContents[6]} />,
    description81: (
        <>
            India’s senior living market is set to expand rapidly, currently
            valued at approximately ₹1.66–₹2.49 lakh crore, and is expected to
            grow significantly to ₹9.96 lakh crore by 2030.{" "}
            <a
                href="https://www.colliers.com/en-in/news/press-release-senior-living-to-grow-5x-by-2030-to-touch-usd12-billion"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                Colliers.
            </a>{" "}
            This growth is driven by a rising ageing population, which is
            expected to make up 21% of India’s total population by 2050 and is
            supported by changing family dynamics like children settling abroad
            or moving to different cities within India and greater financial
            independence among seniors, especially in urban areas.
            <br></br>
            <img
                src={image6}
                alt={tableOfContents[6].text}
                className="image margin"
            />
            <br></br>
            Programs like the Atal Vayo Abhyuday Yojana (AVYAY) provide funding
            for Senior Citizen Homes, aiming to improve the quality of life for
            seniors. Added benefits, such as tax incentives, reduced development
            charges, and flexible zoning, will encourage more senior living
            projects.
        </>
    ),
    title9: <TitleEl content={tableOfContents[7]} />,
    description91: (
        <>
            Emerging technologies are transforming the real estate industry,
            offering significant advantages to the real estate sector. While
            these innovations are still in their early stages, we can expect
            them to make a big splash in the coming year and beyond. These
            advancements are set to completely change the way real estate works,
            making everything faster, smarter, and more efficient. It’s an
            exciting time for the sector, with lots of potential for
            transformation!
        </>
    ),

    list92: [
        {
            label: "AI and predictive analytics",
            description:
                "AI is really changing the game in real estate. With predictive analytics, it helps investors and developers get ahead of the curve by analysing market trends and property values.",
        },

        {
            label: "Virtual and augmented reality",
            description:
                "Virtual reality (VR) and augmented reality (AR) will be making property tours more fun and convenient. VR allows buyers to take a full tour of a property without ever leaving their home, saving time and effort. With AR, you can see how a property would look with your personal touch—whether it’s adding furniture, changing the decor, or even planning a renovation—before you even make a decision. It’s all about helping you visualise the possibilities.",
        },

        {
            label: "IoT integration",
            description:
                "The Internet of Things (IoT) is bringing smart homes into the real estate spotlight. From smart water meters to security systems that you can control remotely, IoT devices are making life easier and more energy-efficient.",
        },

        {
            label: "Fintech solutions",
            description: (
                <>
                    Fintech, the fusion of technology with financial services,
                    has experienced rapid growth in India, positioning the
                    country as one of the fastest- growing fintech markets
                    globally.With the second - largest internet user base, India
                    has quickly embraced financial technology.Key segments that
                    impact this growth include digital lending, digital
                    payments, wealth tech, and blockchain.
                    <br></br>
                    According to{" "}
                    <a
                        href="https://www.pwc.in/assets/pdfs/industries/powering-indias-usd-5-trillion-economy-by-fostering-innovations.pdf"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        leading research reports, the FinTech industry{" "}
                    </a>
                    in India was valued at Rs 1,920 billion in 2019 and is
                    expected to reach Rs 6,207 billion by 2025, expanding at a
                    Compound Annual Growth Rate (CAGR) of approximately 22%
                    during the 2020-2025 period.
                </>
            ),
        },
    ],
    description93: (
        <>
            In real estate transactions, fintech is streamlining the entire
            process. Online payment systems simplify buying, selling, and
            renting properties. Collectively, these innovations drive growth,
            improve customer experience, and create a more dynamic real estate
            market, benefiting stakeholders across both industries.
        </>
    ),
    title10: <TitleEl content={tableOfContents[8]} />,
    description101: (
        <>
            The co-living market in India is set for significant growth, with
            projections indicating a compound annual growth rate{" "}
            <a
                href="https://www.jll.co.in/en/trends-and-insights/research/co-living-reshaping-rental-housing-in-india"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                (CAGR) of 17% from 2020 to 2025.
            </a>{" "}
            This growth is expected to elevate the market's value to
            approximately USD 40 billion (3,509.6 billion INR) by 2025, this
            trend is driven primarily by urbanisation and the evolving lifestyle
            preferences of millennials and Gen Z.
            <img
                src={image7}
                alt={tableOfContents[8].text}
                className="image margin"
            />
        </>
    ),

    title11: <TitleEl content={tableOfContents[9]} />,
    list111: [
        {
            label: "Affordability",
            description:
                "As property prices and rental rates climb, traditional housing options are increasingly out of reach. Co-living offers a budget-friendly solution, where individuals can split costs for rent, utilities, and shared amenities.",
        },
        {
            label: "Convenience and Flexibility",
            description:
                "Co-living spaces are designed for ease, with fully furnished rooms and shared facilities like kitchens, workspaces, and recreational areas. Flexible leases ( short duration like a few months) further simplify moving in or adjusting living arrangements.",
        },
        {
            label: "Social Connection and Community",
            description:
                "Co-living encourages community and social engagement, allowing residents to connect with like-minded individuals, build networks, and find support in a new city.",
        },
    ],

    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            India’s real estate sector is witnessing a dynamic transformation,
            as Tier 2 cities rise as new centres of development and investment.
            Traditionally, Tier 1 cities like Mumbai, Bangalore, and Delhi-NCR
            have led the market with the majority of investments, but recent
            trends reveal a growing shift toward smaller cities. This shift is
            fueled by a unique combination of affordability, strong growth
            potential, and government initiatives.
            <br></br>
            Affordability is a major draw for both buyers and developers in Tier
            2 cities, offering a more accessible alternative to the high prices
            of Tier 1 cities. Reflecting this trend, housing sales in Tier 2
            cities increased by{" "}
            <a
                href="https://www.newindianexpress.com/business/2024/Jul/05/report-reveals-tier-ii-cities-outperform-major-metros-with-11-per-cent-rise-in-housing-sales"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                11% in the last fiscal year{" "}
            </a>{" "}
            , with nearly 208,000 units sold across 30 towns—signifying the
            sector’s expanding reach into these emerging regions.
        </>
    ),
    title13: <TitleEl content={tableOfContents[11]} />,
    description131: (
        <>
            India's real estate market is thriving, with global investors
            showing significant interest, especially in luxury housing and
            cutting-edge infrastructure like data centres. This surge is driven
            by the rising affluence of India's middle and upper classes, which
            drives the demand for high-end properties in cities. With changing
            demographics and evolving aspirations, local investment strategies
            are adapting to align with global trends, opening up exciting
            opportunities for investors looking to tap into India’s expanding
            property market.<br></br>Foreign capital is taking up a major
            portion of investment in real estate—international investments made
            up about{" "}
            <a
                href="https://static.pib.gov.in/WriteReadData/userfiles/file/EconomicSurvey2023Q44O.pdf"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                81% of total investments in Indian real estate between 2017 and
                2022.
            </a>
        </>
    ),
    list132: [
        {
            label: <>Strong demand for Grade A office space</>,
            description:
                "India’s major cities are seeing a surge in demand for top-tier office space as multinational brands and prominent Indian corporations establish headquarters in prime business districts. This trend is fuelled by growing investor confidence, spurred by India’s marked improvement in the ease of doing business rankings.",
        },
        {
            label: (
                <>
                    <a
                        href="http://ndianexpress.com/article/business/economy/government-relaxes-fdi-norms-for-construction-real-estate-sector/"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Relaxation of FDI policy
                    </a>
                </>
            ),
            description:
                "While 100% foreign direct investment in townships, housing, and infrastructure has been allowed since 2005, the Government of India relaxed regulations in 2014, reducing minimum built-up area and capital requirements and making exit norms more flexible for foreign investors in the construction sector.",
        },
        {
            label: (
                <>
                    <a
                        href="https://content.knightfrank.com/research/1801/documents/en/active-capital-the-report-2021-8447.pdf"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Growth in the ‘prop-tech’ space
                    </a>
                </>
            ),
            description:
                "The industrial and logistics segment is seeing rapid momentum, largely driven by demand for warehousing from e-commerce and third-party logistics (3PL) firms. This trend saw industrial and logistics assets attracting nearly a third of foreign investments (USD 1.1 billion) in the real estate sector in 2021.",
        },
        {
            label: <>Investment options like REITs</>,
            description: (
                <>
                    Real Estate Investment Trusts (REITs) are an appealing
                    option for foreign investors, they offer low-risk
                    investment, with strong capital appreciation potential,
                    regular income, and easy exit options, but REITs in India
                    must be listed on recognised stock exchanges. Although still
                    relatively new (with the first Indian REIT, Embassy Office
                    Parks, launched in March 2019), the early success of REIT in
                    India shows the potential it holds and how it can shape the
                    future.
                    <br></br>
                    According to CEO Aravind Maiya, Embassy Office Parks REIT is
                    investing over ₹100 crore to develop metro stations in
                    Bengaluru, underscoring its commitment to enhancing the
                    city’s infrastructure. With developers now working closely
                    with international investors, the sector is seeing higher
                    standards in governance and project management. This shift
                    not only benefits overseas investors but is also shaping a
                    more resilient and competitive real estate market in India.
                    It’s an exciting time for anyone considering a stake in
                    India’s dynamic property landscape.
                </>
            ),
        },
    ],
    title14: <TitleEl content={tableOfContents[12]} />,
    description141: (
        <>
            In Q1{" "}
            <a
                href="https://economictimes.indiatimes.com/industry/services/property-/-cstruction/luxury-homes-overtake-the-affordable-segment-what-gives/articleshow/110039351.cms"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                2024
            </a>
            , luxury homes priced above Rs 1.5 crore accounted for 21% of total
            sales, highlighting the increasing appeal among affluent buyers. As
            India's economy grows and its middle and upper classes expand, more
            individuals gravitate towards a luxury lifestyle. This shift is
            reflected in the rise of high-end residential markets, premium
            retail spaces, and exclusive lifestyle services across the country.
            <br></br>The millennial demographic, which makes up 36% of the
            population, is driving the demand for luxury properties. According
            to a recent{" "}
            <a
                href="https://www.jll.co.in/en/newsroom/new-project-launches-contribute-most-to-h1-2024-residential-sales"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                JLL report
            </a>
            , millennials represented 54% of homebuyers last year, with a
            collective spending power exceeding US$330 billion (approximately
            ₹27,39,000 crore). This trend is expected to strengthen even further
            in the coming year.<br></br> Modern Spaaces caters to premium living
            with offerings like Soulace Premium Villas and Ivy County premium
            plotted development.
        </>
    ),
    title15: <TitleEl content={tableOfContents[13]} />,
    list151: [
        {
            label: <>Property Price Increase</>,
            description:
                "Bangalore’s real estate market is on an upward trajectory, with average property prices expected to climb from approximately ₹7,500 per square foot in 2024 to around ₹7,900 in 2025. For example, in areas like Sarjapur Road, which enjoys prime connectivity to IT hubs and extensive amenities, property values have seen remarkable growth. Over the past year alone, prices in Sarjapur Road surged by 36.3%. In the latest quarter (July-September 2024), apartment prices hit ₹10,800 per square foot, marking an impressive 11% rise from the previous quarter.",
        },
        {
            label: <>Demand Surge</>,
            description:
                "Bangalore’s reputation as a tech hub continues to draw professionals from across India, sustaining housing demand, especially among millennials and Gen-Z buyers who are now prioritising homeownership over renting.",
        },
        {
            label: <>Rental Market Dynamics</>,
            description:
                "Bangalore’s rental market remains robust, driven by a shortage of ready-to-move-in properties. Many new residents, particularly those working near tech parks, continue to seek rental accommodations close to business hubs.",
        },
    ],
    title16: <TitleEl content={tableOfContents[14]} />,
    description161: (
        <>
            India’s real estate sector is poised for a dynamic future, with
            substantial growth projected across various segments. As we head
            toward 2025, the market is set to benefit from evolving trends,
            including multigenerational living, green buildings, senior living,
            and a focus on affordability. Government initiatives and rising
            foreign investments are enhancing transparency, fueling demand, and
            expanding the industry’s reach beyond metro cities into Tier II
            regions. With rising property values, especially in tech hubs like
            Bangalore, and the increasing appeal of sustainable, connected
            communities, the real estate landscape in India is evolving to meet
            the aspirations of diverse homebuyers and investors alike. This
            transformation is creating a vibrant, resilient market ready to meet
            the future needs of Indian society.
        </>
    ),

    Faq: <TitleEl content={tableOfContents[15]} />,

    faqs: [
        {
            label: "Q1: What will be the main drivers behind the growth of Indian real estate in 2025?",
            description: [
                "The primary drivers will be urbanisation, rising income levels, and a booming need for residential, commercial, and data spaces. Advancements in technology and increased foreign investments are also playing crucial roles.",
            ],
        },
        {
            label: "Q2: How are multigenerational living and co-living spaces impacting the real estate market?",
            description: [
                "With rising property prices, many families opt for multigenerational homes, creating demand for larger, versatile living spaces. Similarly, co-living spaces cater to millennials and Gen Z, providing affordable housing solutions with shared amenities and a strong sense of community.",
            ],
        },
        {
            label: "Q3: Why are Tier II cities gaining traction?",
            description: [
                "Tier II cities offer affordable housing, strong growth potential, and government-backed initiatives, making them appealing to both developers and homebuyers. These cities are witnessing increased investments and infrastructure development, enhancing their market appeal.",
            ],
        },
        {
            label: "Q4: How is Bangalore’s property market expected to evolve in 2025?",
            description: [
                <>
                    Bangalore is expected to see steady property value growth,
                    driven by its reputation as a tech hub. Rising demand for
                    housing, especially around tech parks, along with robust
                    commercial and rental activity, will further strengthen the
                    city’s real estate market.
                </>,
            ],
        },
        {
            label: "Q4: What role does technology play in the real estate sector’s future?",
            description: [
                <>
                    Emerging tech—like AI, IoT, and fintech—is revolutionising
                    the sector. Predictive analytics help investors make
                    data-driven decisions, VR tours enhance the buying
                    experience, and IoT devices make smart homes more efficient
                    and convenient, adding tremendous value to real estate.
                </>,
            ],
        },
    ],
};

export default data;
