import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "./index.scss";

import { Accordion, Card } from "react-bootstrap";
import BlogData from "../../../../db/BlogOC&CC";
import ShareButton from "../../Share/ShareButton";
import { BLOGS } from "../../constants";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        window.scrollTo({
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    const [activeKey, setActiveKey] = useState(null);

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>{BLOGS.OC_CC.title}</title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <meta name="description" content={BLOGS.OC_CC.meta}></meta>
                <link
                    rel="canonical"
                    href={`https://modernspaaces.com/blogs/${BLOGS.OC_CC.slug}`}
                />
            </Helmet>
            <section
                className={`section moving-out-made-easy  ${
                    inView ? "inView" : "outView"
                }`}
            >
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                    alt={BlogData.imageAlt}
                />
                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.title1 && (
                                    <p className="-descBlack font-benton-bold">
                                        {BlogData.title1}
                                    </p>
                                )}
                                {BlogData.description11 && (
                                    <Desc
                                        classList="font-benton-bold header"
                                        text={BlogData.description11}
                                    />
                                )}
                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title2}
                                    </p>
                                )}

                                {BlogData.description21 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description21}
                                    />
                                )}

                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title3}
                                    </p>
                                )}

                                {BlogData.description31 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description31}
                                    />
                                )}

                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                {BlogData.list41 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list41.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}
                                {BlogData.description51 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description51}
                                    />
                                )}

                                {BlogData.title6 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title6}
                                    </p>
                                )}

                                {BlogData.description61 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description61}
                                    />
                                )}
                                {BlogData.list62 && (
                                    <ul className="blog-list-circle header">
                                        {BlogData.list62.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <p className="-descBlack mb-1 font-benton-book header">
                                                        {i1}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.description63 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description63}
                                    />
                                )}

                                {BlogData.title7 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title7}
                                    </p>
                                )}
                                {BlogData.description71 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description71}
                                    />
                                )}
                                {BlogData.list72 && (
                                    <ul className="blog-list-circle header">
                                        {BlogData.list72.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <p className="-descBlack mb-1 font-benton-book header">
                                                        {i1}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.title8 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title8}
                                    </p>
                                )}
                                {BlogData.description81 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description81}
                                    />
                                )}

                                {BlogData.title9 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title9}
                                    </p>
                                )}
                                {BlogData.list91 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list91.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.title10 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title10}
                                    </p>
                                )}
                                {BlogData.description101 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description101}
                                    />
                                )}
                                {BlogData.list102 && (
                                    <ul className="blog-list-circle header">
                                        {BlogData.list102.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <p className="-descBlack mb-1 font-benton-book header">
                                                        {i1}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title11 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title11}
                                    </p>
                                )}
                                {BlogData.description111 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description111}
                                    />
                                )}

                                {BlogData.title12 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title12}
                                    </p>
                                )}
                                {BlogData.description121 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description121}
                                    />
                                )}
                                {BlogData.title13 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title13}
                                    </p>
                                )}
                                {BlogData.description131 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description131}
                                    />
                                )}

                                {BlogData.title14 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title14}
                                    </p>
                                )}
                                {BlogData.description141 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description141}
                                    />
                                )}
                                {BlogData.title15 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title15}
                                    </p>
                                )}
                                {BlogData.description151 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description151}
                                    />
                                )}

                                {BlogData.title16 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title16}
                                    </p>
                                )}
                                {BlogData.description161 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description161}
                                    />
                                )}

                                {BlogData.title17 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title17}
                                    </p>
                                )}
                                <div className="comparison-table-wrapper">
                                    <table className="comparison-table">
                                        <thead>
                                            <tr>
                                                {BlogData.table171header.map(
                                                    (headerItem) => {
                                                        return (
                                                            <th>
                                                                <p className="font-benton-book mb-0">
                                                                    {headerItem}
                                                                </p>
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BlogData.table171.map(
                                                (rowItem, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <>
                                                                <td>
                                                                    <Desc
                                                                        classList="my-1 font-benton-bold header "
                                                                        text={
                                                                            rowItem.col1
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col2
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col3
                                                                        }
                                                                    />
                                                                </td>
                                                            </>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {BlogData.title18 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title18}
                                    </p>
                                )}
                                {BlogData.description181 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description181}
                                    />
                                )}
                                <div
                                    className="comparison-table-wrapper"
                                    style={{ overflow: "scroll" }}
                                >
                                    <table className="comparison-table">
                                        <thead>
                                            <tr>
                                                {BlogData.table182header.map(
                                                    (headerItem) => {
                                                        return (
                                                            <th>
                                                                <p className="font-benton-book mb-0">
                                                                    {headerItem}
                                                                </p>
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BlogData.table182.map(
                                                (rowItem, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <>
                                                                <td>
                                                                    <Desc
                                                                        classList="my-1 font-benton-bold header "
                                                                        text={
                                                                            rowItem.col1
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col2
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col3
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col4
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col5
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col6
                                                                        }
                                                                    />
                                                                </td>
                                                            </>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {BlogData.title19 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title19}
                                    </p>
                                )}
                                {BlogData.description191 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description191}
                                    />
                                )}
                                {BlogData.list192 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list192.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title20 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title20}
                                    </p>
                                )}
                                {BlogData.description201 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description201}
                                    />
                                )}
                                {BlogData.list202 && (
                                    <ul className="blog-list-circle header">
                                        {BlogData.list202.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <p className="-descBlack mb-1 font-benton-book header">
                                                        {i1}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title21 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title21}
                                    </p>
                                )}
                                {BlogData.description211 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description211}
                                    />
                                )}
                                {BlogData.list212 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list212.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.description213 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description213}
                                    />
                                )}
                                {BlogData.title22 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title22}
                                    </p>
                                )}
                                {BlogData.description221 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description221}
                                    />
                                )}
                                {BlogData.list222 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list222.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title23 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title23}
                                    </p>
                                )}
                                {BlogData.description231 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description231}
                                    />
                                )}
                                {BlogData.title24 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title24}
                                    </p>
                                )}
                                {BlogData.description241 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description241}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                                activeKey={activeKey}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={() =>
                                                            toggleAccordion(
                                                                faqIdx.toString()
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                                style={{
                                                                    transform:
                                                                        activeKey ===
                                                                        faqIdx.toString()
                                                                            ? "rotate(180deg)"
                                                                            : "rotate(0deg)",
                                                                }}
                                                            >
                                                                <path
                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                    stroke="black"
                                                                    strokeOpacity="0.32"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            <Card.Body className="font-benton-book header p-3">
                                                                {
                                                                    faq.description
                                                                }
                                                            </Card.Body>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton
                                    shareUrl={`https://modernspaaces.com/blogs/${BLOGS.OC_CC.slug}`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
