import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles

import Banner from "./Banner/index.js";
export default function BlogAKhataBKhata() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [inViewShareBlogAKhataBKhata, setInViewShareBlogAKhataBKhata] =
        useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogAKhataBKhata}
                triggerOnce={true}
            >
                {/* <ShareBlogAKhataBKhata
          shareUrl={
            "https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property"
          }
          inView={inViewShareBlogAKhataBKhata}
        ></ShareBlogAKhataBKhata> */}
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
