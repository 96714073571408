export const BLOGS = {
    // DECODING_AREA_METRICS: {
    //     slug: "decoding-carpet-area-super-built-up-area-uds",
    //     title: "Decoding Carpet Area, Super Built-Up Area, Loading Factor, UDS",
    //     time: "8 min read",
    //     date: "December, 2024",
    //     meta: "For a smarter home-buying experience, know the meaning of carpet area, built-up area vs. super-built-up area, and loading factor as used in the industry.",
    // },
    // RISE_OF_WOMEN_HOMEOWNERS: {
    //     slug: "rise-of-women-homeowners",
    //     title: "Rise of Women homeowners: Why are they turning towards real estate?",
    //     time: "8 min read",
    //     date: "December, 2024",
    //     meta: "This blog focuses on the rise of women homebuyers in India and the benefits of helping them achieve financial independence.",
    // },
    HOUSING_TRENDS_2025: {
        slug: "housing-trends-2025",
        title: "Indian Real Estate Outlook: Housing Trends of 2025",
        time: "8 min read",
        date: "January, 2025",
        meta: "What's in store for the Indian real estate market in 2025? Read our blog for insights into emerging housing trends and Key drivers of growth.",
    },
    A_KHATA_B_KHATA: {
        slug: "difference-between-a-khata-and-b-khata",
        title: "Know the Difference between A Khata and B Khata and its impact on your Bangalore Property",
        time: "8 min read",
        date: "January, 2025",
        meta: "Learn about the Khata system and its impact on buying, taxes, and legality to ensure a smooth property-buying journey in Bangalore",
    },
    OC_CC: {
        slug: "importance-of-oc-and-cc",
        title: "Protect Your Investment: The Importance of OC & CC",
        time: "8 min read",
        date: "January, 2025",
        meta: "Buying a home in Bangalore? Learn why an Occupancy Certificate & Completion Certificate are essential for peace of mind",
    },
};
