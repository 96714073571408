import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogKhata1.jpg";
const image2 = "/images/Blogs/BlogKhata2.jpg";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "whatIsKhata",
        text: "What is Khata?",
    },
    {
        id: "khataOrigin",
        text: "How did A Khata and B Khata come into existence?",
    },
    {
        id: "khataExtract",
        text: "What is Khata Extract?",
    },
    {
        id: "khataTitleDeed",
        text: "How is Khata different from Title Deed?",
    },
    {
        id: "khataImportance",
        text: "Why is Khata important for Property Investors and Buyers?",
    },
    {
        id: "khataContents",
        text: "Contents of the Khata",
    },
    {
        id: "khataTypes",
        text: "Types of Khata",
    },
    {
        id: "bKhataProperties",
        text: "List of properties listed by BBMP Covered Under B Khata",
    },
    {
        id: "khataComparison",
        text: "Difference between A Khata and B Khata",
    },
    {
        id: "bKhataLoan",
        text: "Is it possible to get a loan on B khata property?",
    },
    {
        id: "panchayatKhata",
        text: "Panchayat Khata(E- Khata)",
    },
    // {
    //     id: "eKhataAdvantages",
    //     text: "Advantages of Owning an E-Khata",
    // },
    {
        id: "khataConversion",
        text: "Is it possible to convert B Khata into A Khata?",
    },
    {
        id: "khataTransferRequirement",
        text: "When is Khata Transfer Required?",
    },
    {
        id: "transferTimeline",
        text: "How long does it take to complete a Khata transfer?",
    },
    {
        id: "departmentRoles",
        text: "The Importance of the following departments in Khata transaction:",
    },
    {
        id: "transferProcess",
        text: "How to do Khata transfer in Bangalore?",
    },
    {
        id: "transferCharges",
        text: "Khata transfer charges in Bangalore",
    },
    {
        id: "khataEssential",
        text: "Why is having a Khata essential?",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQs ( Frequently Asked Questions)",
    },
];
const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};
const blogConstant = BLOGS.A_KHATA_B_KHATA;

const data = {
    slug: blogConstant.slug,
    image: image1,
    imageAlt: blogConstant.title,
    title: blogConstant.title,
    time: blogConstant.time,
    publishedOn: blogConstant.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: (
        <>
            Are you still searching for your dream home, or have you already
            found the perfect property? Before you take the next step, it's
            crucial to understand a few key technical terms related to Bangalore
            real estate, especially the Khata system. Many property buyers find
            themselves overwhelmed by the legal jargon and complexities. To
            ensure a smooth and informed buying experience, it’s important to
            familiarise yourself with these terms. Trust me, once you read the
            blog, you’ll realise it’s simpler than it seems. Go ahead and read
            the blog to become an informed homeowner.
        </>
    ),

    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            The word "Khata" literally means account and holds significant
            importance in property transactions. It is a crucial legal document
            that functions as an official record, that recognises and certifies
            specific properties in the city. This simplifies processes like:
        </>
    ),
    list32: [
        "Tax payments",
        "Licensing",
        "Obtaining loans",
        "Property approvals",
        "Official procedures",
    ],

    title4: <TitleEl content={tableOfContents[2]} />,
    description41: (
        <>
            The concept came into importance in 2007 with the establishment of
            the{" "}
            <span className="font-benton-bold">
                {" "}
                BBMP (Bruhat Bengaluru Mahanagara Palike)
            </span>
            , the municipal corporation of Bangalore. This move was aimed at
            introducing a standardised tax assessment across the city.
        </>
    ),

    title5: <TitleEl content={tableOfContents[3]} />,
    description51: (
        <>
            It is an official document issued by the Bruhat Bengaluru Mahanagara
            Palike (BBMP) that provides detailed information about a property.
            This extract includes essential details such as:
        </>
    ),

    list52: [
        "Property taxes due",
        "Size of the property",
        "Location of the property",
        "Built-up area of the property",
        "Purpose of utilisation",
        "Annual value",
    ],
    description53: (
        <>
            While a Khata document establishes the owner's identity and rights,
            a Khata Extract provides comprehensive details about the property.
            Both documents are issued together by the BBMP and serve
            complementary purposes in property ownership, transactions, and
            taxation.
        </>
    ),
    image2: image2,
    image2Alt: "Khata Extract",
    title6: <TitleEl content={tableOfContents[4]} />,

    description61: (
        <>
            Many people mistakenly believe that both documents are the same. In
            reality, a <span className="font-benton-bold">Title Deed</span> is a
            written document between the buyer and the seller during a property
            transfer and proves ownership of the property. On the other hand, a
            <span className="font-benton-bold">Khata certificate</span> is
            primarily used for property tax purposes and maintaining official
            records. It verifies the property's existence and helps assess the
            applicable tax amount.
        </>
    ),

    title7: <TitleEl content={tableOfContents[5]} />,
    list71: [
        {
            label: "Confidence in Ownership",
            description:
                " It verifies the property’s legitimacy, giving you peace of mind that you are investing in legal property.",
        },

        {
            label: "Informed Decisions",
            description: (
                <>
                    A comprehensive record of the property, including its
                    details and any relevant history.
                </>
            ),
        },

        {
            label: <>Simplified Transactions</>,
            description: (
                <>
                    Essential for securing property tax documents and ensuring
                    you can pay taxes smoothly. This avoids future
                    complications.
                </>
            ),
        },
        {
            label: <>Access to Utilities</>,
            description: (
                <>
                    Obtaining water and electricity connections for your
                    property becomes easier.
                </>
            ),
        },
        {
            label: <>Loan Eligibility</>,
            description: (
                <>
                    A Khata makes it easy to secure loans from banks and housing
                    finance institutions for your property purchase.
                </>
            ),
        },
    ],

    title8: <TitleEl content={tableOfContents[6]} />,
    description81: (
        <>
            They contain vital information like owner name, size, location,
            built-up area, and official identification details like the property
            number, and extent of the property. This information is essential
            for the authorities to accurately assess your property tax.
        </>
    ),
    title9: <TitleEl content={tableOfContents[7]} />,
    list91: [
        {
            label: <>A Khata</>,
            description: (
                <>
                    This signifies that the property is as per the norms
                    stipulated by the statutory authorities and permanent
                    property records. It has a clear title and means that
                    property taxes are cleared, making it easier to handle
                    financial transactions, apply for licenses, and seek loans.
                </>
            ),
        },
        {
            label: <>B Khata</>,
            description: (
                <>
                    This is a temporary document issued for properties that are
                    considered semi-legal or in violation of building bylaws and
                    regulations.
                </>
            ),
        },
    ],

    title10: <TitleEl content={tableOfContents[8]} />,
    description101: (
        <>
            The Bruhat Bengaluru Mahanagara Palike (BBMP) includes properties
            that match any of the following shortcomings:
        </>
    ),
    list102: [
        {
            label: <>1. In violation of specific bylaws</>,
            description: (
                <>
                    Properties constructed without adhering to the approved
                    building plans or violating zoning regulations are
                    categorised under it.
                </>
            ),
        },
        {
            label: <>2. Constructed upon revenue land</>,
            description: (
                <>
                    Properties built on revenue land without proper permissions
                    are considered illegal.
                </>
            ),
        },
        {
            label: <>3. Constructed in unauthorised layouts</>,
            description: (
                <>
                    Properties built-in layouts that are not approved by the
                    authorities.
                </>
            ),
        },
        {
            label: (
                <>4. Properties without completion or issuance certificates</>
            ),
            description: (
                <>
                    Properties that do not have the necessary completion or
                    issuance certificates are considered illegal and are listed
                    under B Khata.
                </>
            ),
        },
    ],

    title11: <TitleEl content={tableOfContents[9]} />,
    table111header: ["Aspect", "A Khata", "B Khata"],
    table111: [
        {
            col1: "Definition",
            col2: "Fully legal and compliant properties",
            col3: "It may not be fully compliant, with some issues",
        },
        {
            col1: "Legality",
            col2: "Fully recognised by BBMP",
            col3: "Not fully recognised, limited rights",
        },
        {
            col1: "Property Tax",
            col2: "Paid and up-to-date",
            col3: "May have pending taxes",
        },
        {
            col1: "Transactions",
            col2: "Can be easily sold or transferred",
            col3: "Transactions restricted",
        },
        {
            col1: "Building Permits",
            col2: "Eligible for building permits",
            col3: "Not eligible until converted",
        },
        {
            col1: "Loans",
            col2: "Can get loans with ease",
            col3: "Hard to get loans",
        },
        {
            col1: "License",
            col2: "Trade license is easily obtained",
            col3: "Trade license cannot be issued",
        },
        {
            col1: "Conversion",
            col2: "No conversion is needed",
            col3: "Needs regularisation",
        },
        {
            col1: "Building Plan",
            col2: "The building layout is approved by the BBMP",
            col3: "Not approved by BBMP",
        },
        {
            col1: "Civic Charges",
            col2: "Eligible to pay all civic charges",
            col3: "Might face restrictions but still, they have to clear all dues",
        },
        {
            col1: "Occupancy Certificate (OC)",
            col2: "Easy to obtain OC because the building follows the approved plan and layout",
            col3: "Usually, they do not qualify for OC",
        },
        {
            col1: "Water Connection",
            col2: "Easier to obtain a legal connection from BWSSB",
            col3: "Difficult to obtain legal connection",
        },
        {
            col1: "Betterment Charges",
            col2: "Streamlined process",
            col3: "It is payable but involves a lot of legal complexities",
        },
    ],

    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            Yes, it is possible to get a loan against B Khata properties, but it
            can be difficult due to legal issues linked to unauthorised
            construction. Most national banks typically do not offer loans for B
            Khata properties. However, some private banks may provide loans, but
            the interest rates are generally higher.
        </>
    ),
    title13: <TitleEl content={tableOfContents[11]} />,
    description131: (
        <>
            It is an official document issued by the Gram Panchayat, similar to
            the one issued by the Bruhat Bengaluru Mahanagara Palike (BBMP). It
            applies to properties within urban areas falling under Gram
            Panchayat jurisdiction, even if those villages are located within
            the broader Bangalore district.
            <br />
            <span className="font-benton-bold">For example:</span> While a major
            part of Marathahalli falls under BBMP limits some pockets on the
            outskirts, especially towards the north and east, might still be
            under Gram Panchayat control and have Panchayat Khata. This is
            because Bangalore's urban sprawl is constantly expanding, and some
            villages are gradually being integrated into the city limits.
        </>
    ),

    title14: <TitleEl content={tableOfContents[12]} />,
    description141: (
        <>
            Yes, it is possible, but before a conversion application can be
            filed one has to clear all the prescribed tax dues, penalties,
            property taxes, DC conversion charges, and the betterment charges
            levied by the relevant authorities.
        </>
    ),
    title15: <TitleEl content={tableOfContents[13]} />,
    description151: (
        <>
            Transfer is mandatory whenever ownership of a property changes
            hands. This can happen due to:
        </>
    ),
    list152: ["Sale", "Gift", "Will", "Death of the owner"],
    description153: (
        <>
            To initiate the process, you have to submit a requisition letter
            along with the following documents: the title deed, tax payment
            receipts, details, national savings certificate, death certificate
            (if applicable), and an affidavit declaring the legal heirs.
        </>
    ),
    title16: <TitleEl content={tableOfContents[14]} />,
    description161: (
        <>
            Expect the transfer process to be completed within 30 to 45 days,
            depending on various factors like
        </>
    ),
    list162: [
        {
            label: <>Document Accuracy</>,
            description: (
                <>
                    All submitted documents must be complete and accurate to
                    avoid delays in processing or revisions.
                </>
            ),
        },
        {
            label: <>BBMP Inspection</>,
            description: (
                <>
                    The Bangalore Municipal Corporation (BBMP) may conduct a
                    property inspection, which can impact the timeline.
                </>
            ),
        },
        {
            label: <>Incomplete Documents</>,
            description: (
                <>
                    Missing or incorrect documents will require resubmission,
                    causing delays.
                </>
            ),
        },
        {
            label: <>Bureaucratic Delays</>,
            description: (
                <>
                    The transfer process can sometimes be frustratingly slow due
                    to bureaucratic hurdles. The transfer process relies on the
                    interconnected work of the BBMP, Sub-Registrar, and Revenue
                    Department. This interdependence can cause delays if any
                    department faces an increased workload or any other issue.
                </>
            ),
        },
    ],
    title17: <TitleEl content={tableOfContents[15]} />,

    list171: [
        {
            label: <>BBMP (Bruhat Bengaluru Mahanagara Palike)</>,
            description: (
                <>
                    Maintaining and updating property records, assessing
                    property taxes, and issuing
                </>
            ),
        },
        {
            label: <>Sub-Registrar</>,
            description: (
                <>
                    The Sub-Registrar acts as a legal custodian, ensuring proper
                    documentation and adherence to legal regulations during
                    property transactions by registering the sale or transfer of
                    property rights.
                </>
            ),
        },
        {
            label: <>Revenue Department</>,
            description: (
                <>
                    The Revenue Department plays a vital role in verifying the
                    complete payment of property taxes before approving the
                    transfer of Khata certificates.
                </>
            ),
        },
    ],
    title18: <TitleEl content={tableOfContents[16]} />,
    description181: (
        <span className="font-benton-bold">
            Online Transfer via Sakala Portal:
        </span>
    ),
    list182: [
        {
            label: <>Step 1</>,
            description: (
                <>
                    Visit the official Sakala website
                    <a
                        href="https://sakala.kar.nic.in/"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        (https://sakala.kar.nic.in/).
                    </a>
                </>
            ),
        },
        {
            label: <>Step 2</>,
            description: (
                <>
                    Select "Khata Transfer" and download the online application
                    form.
                </>
            ),
        },
        {
            label: <>Step 3</>,
            description: (
                <>
                    Enter your name as it appears on your Aadhaar card, property
                    details (type, measurements, status), and contact
                    information (mobile number and email address) as per the
                    Sale Deed.
                </>
            ),
        },
        {
            label: <>Step 4</>,
            description: (
                <>
                    Attach all necessary documents and submit the completed
                    application form online.
                </>
            ),
        },
        {
            label: <>Step 5</>,
            description: (
                <>
                    <span className="font-benton-bold">BBMP</span> will Assess
                    the property and notify you of the charges.
                </>
            ),
        },
        {
            label: <>Step 6</>,
            description: <>Collect it from the BBMP office.</>,
        },
    ],
    title19: <TitleEl content={tableOfContents[17]} />,
    description191: (
        <>
            The registration fee is typically calculated as 2% of the property's
            value stated in the sale deed.
            <br />
            Let's say you have a property with a sale deed that states its value
            as Rs. 50,00,000 (50 lakhs). To calculate the transfer fee, you
            would take 2% of this value:
            <br /> 2% of Rs. 50,00,000 = 0.02 * 50,00,000 = Rs. 1,00,000
            <br /> So, the transfer fee for this property would be Rs. 1,00,000.
        </>
    ),
    title20: <TitleEl content={tableOfContents[18]} />,
    description201: (
        <>
            To sum it up it is one of the important documents that a homeowner
            must possess to verify ownership of the property and enjoy the
            benefits associated with it.
        </>
    ),

    list202: [
        "Provides complete information about the property, such as type, size, location, area, flat or house number, number of floors, and establishment date.",
        "Assists property owners in obtaining trade licenses and loans.",
        "Simplifies the process of filing and paying property taxes.",
        "Helps in securing electricity and water connections for the property.",
    ],
    title21: <TitleEl content={tableOfContents[19]} />,
    description211: (
        <>
            Each state in India has its own requirements for property documents.
            By understanding the legal status of a property, you can make a
            better decision when buying your home. Choosing a property with
            complete documentation ensures a smoother ownership experience and
            helps avoid future financial issues.
        </>
    ),
    Faq: <TitleEl content={tableOfContents[20]} />,

    faqs: [
        {
            label: "Q1: What are the limitations of a B Khata property in Bangalore?",
            description: [
                <>
                    It can have legal and resale challenges, limited services,
                    and potential future legal complications.
                </>,
            ],
        },
        {
            label: "Q2: What are the documents required for the Khata transfer?",
            description: [
                "The documents required depend on the type of transfer (sale, inheritance, etc.). However, some common documents include the Sale deed, Title deed, Latest tax-paid receipts, and Khata certificate.",
            ],
        },
        {
            label: "Q3: Who can apply for a Khata?",
            description: [
                'The rightful owner, or "title holder" of a property can apply. They will need to submit a completed application form along with the relevant documents.',
            ],
        },
        {
            label: "Q4:  Is khata proof of property ownership?",
            description: [
                <>
                    No, a <span className="font-benton-bold">Khata</span> is not
                    proof of property ownership. It is a revenue document issued
                    by the local municipal authority, which records details
                    related to the property for tax purposes.
                </>,
            ],
        },
        {
            label: "Q5: How can I ensure my Khata transfer application is complete?",
            description: [
                <>
                    To ensure your Khata transfer application is complete, keep
                    a detailed checklist of required documents, double-check all
                    information for accuracy, and consult with BBMP or a legal
                    expert if needed.
                </>,
            ],
        },
        {
            label: "Q6: What should I do if my Khata transfer is delayed?",
            description: [
                <>
                    If your Khata transfer is delayed, follow up with the BBMP
                    office, check the status online, and if a reasonable time
                    has passed then escalate and try to resolve the issue by
                    contacting higher officials or using grievance redressal
                    mechanisms.
                </>,
            ],
        },
    ],
};

export default data;
